module.exports={
    login:{
        title:"标题"

    },
    ABOUT:"关于",
    BUY:"购买",
    TOKENOMICS:'TOKENOMICS',
    ROADMAP:'ROADMAP',
    FAQS:'FAQS',
    WHITE_PAPER:'WHITE PAPER',
    AUDITS:'AUDITS',
    STAKING:'STAKING',

    SEC_INTRODUCE:'30sec是一个Web3应用程序，可以通过观看广告来赚钱。\n\n我们正在创造一种可持续的经济模式，通过观看加密货币广告来赚钱。\n\n为了让更多的人参与到这个关于“时间经济”的实验中来，我们发起了#30secCasualProgram和#30secBoredProgram来深入研究关于“永久收入”和“无聊”的经济实验，并以空投的方式赠送有限的300亿美元NOTYET和300亿美元BORED !\n\n现在就加入这个实验，尽情享受吧!在每个人每时每刻都能获利的经济模式中抢占先机。',

    Road_Q1_Title:'2023 Q1',
Road_Q1_content:'Project Design and Development\n\n' + 'Testnet Beta Open\n' + 'Whitepaper Launch\n' + 'Website Launch\n' + 'Social Media Launch',

Road_Q2_Title:'Q2',
Road_Q2_content:'Mainnet App Open\n' + 'Airdrop Event\n' + 'Token Launch Event\n' + 'Task System\n' + 'Privilege System\n' + 'User Leveling System\n\n' + 'Protocols/Smart Contracts Integration',

}