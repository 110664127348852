import  { createI18n }  from 'vue-i18n'
import zh from '../statics/i18n/zh';
import en from '../statics/i18n/en';
const  i18n= createI18n({
    locale: 'en', // 语言标识（缓存里面没有就用中文）
    fallbackLocale:'en' , //没有英文的时候默认中文语言
    messages: {
        zh,
        en
    }
})

export default i18n;