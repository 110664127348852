module.exports={
    login:{
        title:"this title"
    },
    ABOUT:"ABOUT",
    BUY:"BUY",
    TOKENOMICS:'TOKENOMICS',
    ROADMAP:'ROADMAP',
    FAQS:'FAQS',
    WHITE_PAPER:'WHITE PAPER',
    AUDITS:'AUDITS',
    STAKING:'STAKING',
    WHATE30SEC:'WHAT’S 30SEC',
    Web3app :'30sec is a Web3 app that watches ads to earn.\n' +
'\n' +
'We are creating a sustainable economic model for earning money by watching ads with cryptocurrency.\n' +
'\n' +
'To get more people involved in this experiment on the “Time Economy”, we launched the #30secCasualProgram and  #30secBoredProgram to dive deep into the Economic Experiment on “Permanent Incomes” and “Boredom”, giving away a limited 30,000,000,000 $NOTYET and 30,000,000,000 $BORED in airdrops!\n' +
'\n' +
'Join the experiment now and have a blast! Get a head start in an economic model where everyone can profit every sec.',


SEC_INTRODUCE:'30sec is a Web3 app that watches ads to earn.\n\nWe are creating a sustainable economic model for earning money by watching ads with cryptocurrency.\n\nTo get more people involved in this experiment on the “Time Economy”, we launched the #30secCasualProgram and #30secBoredProgram to dive deep into the Economic Experiment on “Permanent Incomes” and “Boredom”, giving away a limited 30,000,000,000 $NOTYET and 30,000,000,000 $BORED in airdrops!\n\nJoin the experiment now and have a blast! Get a head start in an economic model where everyone can profit every sec.',


Road_Q1_Title:'2023 Q1',
Road_Q1_content:'Project Design and Development\n\n' + 'Testnet Beta Open\n\n' + 'Whitepaper Launch\n\n' + 'Website Launch\n\n' + 'Social Media Launch',

Road_Q2_Title:'Q2',
Road_Q2_content:'Mainnet App Open\n\n' + 'Airdrop Event\n\n' + 'Token Launch Event\n\n' + 'Task System\n\n' + 'Privilege System\n\n' + 'User Leveling System\n\n\n' + 'Protocols/Smart Contracts Integration',


Road_Q3_Title:'Q3',
Road_Q3_content:'Brand Partnerships\n\n' +'Brand Backend Develop\n(Advertising System)\n\n' + 'Branding Features Update\n(Brandpage, Follow)\n\n' + 'Survey Features Update\n(Comments, Questionnaires, Reports)\n\n' + 'App Localization\n\n' + 'Cryptocurrency Exchanges Listing\n\n' + 'On-chain Upgrade',

Road_Q4_Title:'Q4',
Road_Q4_content:'In-app Store\n\n' +'“YETCOFFEE” Global Crypto-payment \nCharming Stores Program\n\n' + '“YETGOODS” Co-branding Products\n\n' + 'Brand Backend Develop\n(Sales System)\n\n\n' + 'DAO “Blackpaper” Launch\n\n' + 'Foundation Formation\n\n' + 'Cross-chain Support',

Road_Q5_Title:'2024',
Road_Q5_content:'“YETSTORE” Global Crypto-payment \nCharming Stores Program\n\n' +'“NOT YET DAY” Brand Carnival\n\n\n' + 'Video Channel System\n\n' + 'Social Features Update\n(Friends, Messaging, Tipping)\n\n' + 'Premium Brand Services\n\n\n' + 'DAO Token Launch\n\n' + 'DeFi Program Launch',

    buy:{
        CreateYourMetaMask: "1. Create Your MetaMask Wallet",
        If_you_already_have_a_compatible:"If you already have a compatible wallet–go to step 2. If not, we suggest Metamask. On PC, it’s a browser extension. On Mobile, it’s an app. Simply go to Download MetaMask: The Premier Blockchain Wallet App and Browser Extension.",
        LoadWalletWithCrypto:"2. Load Wallet With Crypto",
        Paying_by_card:"Paying by card? Go to step 3. If paying by crypto, load your fave crypto onto your wallet. ETH, USDT, and BNB are accepted.",
        Buy_and_Stake:"3. Buy and Stake $PEPU",
        Want_MOAR_GAINZZZZ:"Want MOAR GAINZZZZ? Connect your wallet to the website. Choose a payment method. Choose the amount of $PEPU you want to buy–then Buy or Buy and Stake for max gainz.",

    },
    roadmap:{
        development:"Project Design and Development\n\nTestnet Beta Open\nWhitepaper Launch\nWebsite Launch\nSocial Media Launch",
    }
}