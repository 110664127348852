import axios from "axios";
import router from '../router'

/****** 创建axios实例 ******/
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // api的base_url
    // baseURL: '/api', // api的base_url
    timeout: 5000000 // 请求超时时间
});

service.interceptors.request.use(config => {
        // 修改请求头信息
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = JSON.parse(localStorage.getItem('token'))
        }
        return config
    })
    //axios请求拦截
service.interceptors.response.use(
    res => { //成功请求到数据
        if (res.data.code == 200) {
            return res.data
        } else if (res.data.code == 401) {
            localStorage.clear();
            router.push({ path: "/login" })
        } else {
            return res;
        }
    },
    error => { //响应错误处理
        console.log('error');
        console.log(error);
        console.log(JSON.stringify(error));
        return Promise.reject(error)
    }
);

//请求方法封装，拷贝直接用~
export default {
    GET(url, data = {}) {
        return service({
            url: url,
            method: 'get',
            params: data,
            data: ""
        })
    },

    POST(url, data = {}) {
        return service({
            url: url,
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
        })
    },

    PUT(url, data = {}) {
        return service({
            url: url,
            method: 'put',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
        })
    },

    DELETE(url, data = {}) {
        return service({
            url: url,
            method: 'delete',
            data: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
    }
}